import {
  Backdrop,
  CircularProgress,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { MONTHS } from "app/configs/globals";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import fileDownload from "js-file-download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import { useState } from "react";
import { downloadMyPaySlip } from "../../human_ressources/pay_slip/PaySlipServices";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.2rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaySlipTableBody({ rows }) {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  function handleDownloadMyPaySlip(month, year) {
    setOpenBackDrop(true);
    downloadMyPaySlip(month, year)
      .then((res) => {
        fileDownload(res.data, `${month}_${year}.pdf`);
      })
      .catch((res) => {
        alert(
          "Une erreur s'est produite lors de la récupération de la fiche de paie"
        );
      })
      .finally(() => setOpenBackDrop(false));
  }

  async function asyncDownloadFile(month, year) {
    setOpenBackDrop(true);
    downloadMyPaySlip(month, year)
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
      })
      .catch((response) => {
        alert(
          "Une erreur s'est produite lors de la récupération de la fiche de paie"
        );
      })
      .finally(() => setOpenBackDrop(false));
  }

  return (
    <>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={`${row.code}_${row.month}_${row.year}`}>
            <StyledTableCell align="left">{MONTHS[row.month]}</StyledTableCell>
            <StyledTableCell align="left">{row.year}</StyledTableCell>
            <StyledTableCell scope="row">
              <IconButton
                aria-label="download"
                onClick={() => handleDownloadMyPaySlip(row.month, row.year)}
              >
                <DownloadForOfflineIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
              <IconButton
                aria-label="download"
                onClick={() => asyncDownloadFile(row.month, row.year)}
              >
                <VisibilityIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
